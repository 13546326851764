<template>
  <div class="mt-4">
    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h1 class="page-title">
        <b-icon icon="people-fill" />
        {{ $t("orgs.title") }}
      </h1>
    </div>

    <b-card border-variant="light" class="mt-4 shadow-sm card-rounded">
      <b-form @submit.prevent="search">
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              autocomplete="off"
              data-cy="search-input"
              :placeholder="$t('typeToSearch')"
              type="search"
            />

            <b-input-group-append>
              <b-button data-cy="user-clear-search-button" @click="clearFilter">
                <b-icon-x />
              </b-button>

              <b-button
                variant="primary"
                data-cy="user-search-button"
                type="submit"
              >
                {{ $t("search") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-form>

      <div class="overflow-auto">
        <b-table
          :busy="loading"
          :current-page="currentPage"
          :fields="fields"
          :head-variant="'light'"
          :items="tenants"
          :per-page="0"
          class="mb-0"
          data-cy="organizations-table"
          fixed
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-2" />
              <strong> {{ $t("loading") }} </strong>
            </div>
          </template>

          <template #cell(name)="data">
            <router-link
              v-if="isDisabled(data.item.name)"
              :to="'/organization/' + data.item.id"
            >
              {{ data.item.displayName }}
            </router-link>

            <p v-else class="mb-0">
              {{ data.item.displayName }}
            </p>
          </template>
        </b-table>

        <b-pagination
          v-if="total > pageSize"
          v-model="currentPage"
          :per-page="pageSize"
          :total-rows="total"
          align="right"
          class="mb-0 mt-3"
          first-text="First"
          last-text="Last"
          next-text="Next"
          prev-text="Prev"
        />
      </div>

      <b-card-text
        v-if="tenants.length === 0 && !loading"
        class="text-center my-4"
      >
        {{ $t("orgs.emptyTable") }}
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "Organizations",
  data() {
    return {
      tenants: [],
      pageSize: 10,
      total: null,
      currentPage: 1,
      fields: [
        { key: "name", label: this.$i18n.t("homeOrg.orgs") },
        { key: "role", label: this.$i18n.t("homeOrg.role") },
      ],
      activeRole: this.$store.getters.activeRole?.split("@"),
      loading: true,
      roles: this.$store.getters.roles,
      filter: null,
    };
  },
  mounted() {
    this.tenantsProvider();
  },
  methods: {
    // delete a tenant
    async deleteTenant(id) {
      if (confirm("Are you sure you want to delete the user?")) {
        try {
          await this.$http.delete(
            `${this.$cfg.BASE_IDENTITY_VERSION_URL}/tenants/${id}`
          );
          await this.tenantsProvider();
        } catch (err) {
          if (err.response) console.log(err.response);
        }
      }
    },

    // get tenants
    async tenantsProvider() {
      this.loading = true;
      try {
        const {
          data: { content, page },
        } = await this.$http.get(
          this.$cfg.BASE_IDENTITY_VERSION_URL + "/tenants",
          {
            params: {
              page: this.currentPage - 1,
              pageSize: this.pageSize,
              own: true,
              search: this.filter,
            },
          }
        );

        this.tenants = content.map((el) => {
          const organizationRole = this.roles.filter((role) => {
            return role.substr(role.indexOf("@") + 1) === el.name;
          });

          el.role = organizationRole
            .map((role) => role.substr(0, role.indexOf("@")))
            .join(", ");

          return el;
        });

        this.total = page.totalElements;
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
      }
    },

    search() {
      this.loading = true;
      this.currentPage = 1;
      this.tenantsProvider();
    },

    clearFilter() {
      this.filter = null;
      this.currentPage = 1;
      this.tenantsProvider();
    },

    // check if user is admin of organization
    isDisabled(name) {
      if (
        (this.activeRole[0] === "admin" && this.activeRole[1] === name) ||
        this.activeRole[0] === "system.admin"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    currentPage: {
      handler: function () {
        this.tenantsProvider();
      },
    },
  },
};
</script>

<style scoped></style>
